import { withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import ScheduleForm from './components/ScheduleForm';
import LoginForm from './components/LoginForm';


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridContainer: {
    paddingTop: '20px',
    paddingLeft: '50px',
    paddingRight: '50px',
  }
})



class App extends Component {
  constructor() {
    super();
    this.state = {
      sideBarOpen: false
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }
  handleDrawerOpen() {
    this.setState({
      sideBarOpen: true
    });
  }
  handleDrawerClose() {
    this.setState({
      sideBarOpen: false
    });
  };
  render() {
    return (
      <Fragment>
        <NavBar handleDrawerOpen={this.handleDrawerOpen}></NavBar>
        <SideBar handleDrawerClose={this.handleDrawerClose} open={this.state.sideBarOpen}></SideBar>
        <Switch>
          <Route exact path="/" component={ScheduleForm}></Route>
          <Route path="/login" component={LoginForm}></Route>
        </Switch>
      </Fragment>

    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
