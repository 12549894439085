import { Grid, Paper, Typography, withStyles, TextField, InputAdornment, Button } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import React, { Component, Fragment } from 'react';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridContainer: {
    paddingTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: {
    paddingLeft: theme.spacing(1)
  },
});

function handleLogin(e) {
  e.preventDefault();
  let ret = '';
  if (window.HWH5) {
    window.HWH5.getDeviceInfo().then(data => {
      document.getElementById('h5rt').innerText = data;
    })
  }

}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const id = target.id;
    this.setState({
      [id]: value
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    axios.post('https://lug.hit.edu.cn/v1/schedule/auth/registertoken').then(
      (response) => {
        console.log('Token: '+ response.data.token);
        localStorage.setItem('schedule-token', response.data.token);
        let data = new FormData();
        data.append('type', 'undergraduate');
        data.append('xh', this.state.username);
        data.append('pw', this.state.password);
        data.append('token', response.data.token);
        axios.post('https://lug.hit.edu.cn/v1/schedule/auth/login', data).then((response) => {
          console.log('Login success');
          document.getElementById('h5rt').innerText = "Login success";
        })
      }
    )
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid container item className={classes.gridContainer} justify="center">
          <Grid item xs={12} md={10} xl={9}>
            <Typography variant="h4" className={classes.title}>登录</Typography>
            <Paper className={classes.paper} elevation={5}>
              <form>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      id="username"
                      name="username"
                      label="学号"
                      value={this.state.username}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        )
                      }}
                      required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="password"
                      name="password"
                      label="密码"
                      type="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        )
                      }}
                      required />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{
                        marginTop: '10px'
                      }}
                      onClick={this.handleSubmit}
                    >
                      登录
                  </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={10} xl={9} style={{ marginTop: '10px' }}>
            <Paper className={classes.paper} id="h5rt">
              233333
          </Paper>
          </Grid>
        </Grid>
      </Grid >
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginForm);