import { Grid, Paper, Typography, withStyles, FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import axios from 'axios';

import React, { Component, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import ScheduleCell from './ScheduleCell';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridContainer: {
    paddingTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: {
    paddingLeft: theme.spacing(1)
  },
  firstCell: {
    padding: theme.spacing(0.5)
  }
});

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '',
      semester: '',
      week: '',
      scheduleCourse: '',
      events: new Array(),
      semesterStart: {
        '2018-2019-1': 1535904000000,
        '2018-2019-2': 1551024000000,
        '2018-2019-3': 1561910400000,
        '2019-2020-1': 1567353600000,
        '2019-2020-2': 1582473600000,
        '2019-2020-3': 1593360000000,
        '2020-2021-1': 1599408000000,
        '2020-2021-2': 1615132800000,
        '2020-2021-3': 1626019200000,
      },
    }
    this.updateCurrentCourse = this.updateCurrentCourse.bind(this);
    this.handleSemesterChange = this.handleSemesterChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleWeekChange = this.handleWeekChange.bind(this);
  }

  updateCurrentCourse(upd) {
    const current_state = { ...this.state, ...upd };
    const courses = JSON.parse(localStorage.getItem('schedule-course'));
    if (!Array.isArray(courses)) return;
    const semesterStart = this.state.semesterStart[current_state.year + '-' + current_state.semester];
    const weekStart = semesterStart + (current_state.week - 1) * 7 * 24 * 60 * 60 * 1000;
    const events = new Array();
    for (let c of courses) {
      if (c.XN === current_state.year && c.XQ === current_state.semester && c.ZC[current_state.week] === '1') {
        let ans = new Array();
        const regex = /\d+/gm;
        let m;
        while ((m = regex.exec(c.SKSJMS)) !== null) {
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }
          m.forEach((match, groupIndex) => {
            ans.push(match);
          });
        }
        function getStartTime(s) {
          switch (s) {
            case '1':
              // 08:00
              return 8 * 60 * 60 * 1000;
            case '2':
              // 08:55
              return (8 * 60 + 55) * 60 * 1000;
            case '3':
              // 10:00
              return 10 * 60 * 60 * 1000;
            case '4':
              // 10:55
              return (10 * 60 + 55) * 60 * 1000;
            case '5':
              // 13:45
              return (13 * 60 + 55) * 60 * 1000;
            case '6':
              // 14:40
              return (14 * 60 + 40) * 60 * 1000;
            case '7':
              // 15:45
              return (15 * 60 + 45) * 60 * 1000;
            case '8':
              // 16:40
              return (16 * 60 + 40) * 60 * 1000;
            case '9':
              // 18:30
              return (18 * 60 + 30) * 60 * 1000;
            case '10':
              // 19:25
              return (19 * 60 + 25) * 60 * 1000;
            case '11':
              // 20:30
              return (20 * 60 + 30) * 60 * 1000;
            case '12':
              // 21:25
              return (21 * 60 + 25) * 60 * 1000;
            default:
              return 0;
          }
        }
        events.push({
          title: c.KCMC + ' @ ' + c.CDMC,
          start: new Date(weekStart + (c.XQJ - 1) * 24 * 60 * 60 * 1000 + getStartTime(ans[0])),
          end: new Date(weekStart + (c.XQJ - 1) * 24 * 60 * 60 * 1000 + getStartTime(ans[ans.length - 1]) + 50 * 60 * 1000),
        });
      }
    }
    this.setState({
      events: events
    })
  }
  handleSemesterChange(event) {
    this.setState({
      semester: event.target.value
    })
    this.updateCurrentCourse({ semester: event.target.value });
  }
  handleYearChange(event) {
    this.setState({
      year: event.target.value
    })
    this.updateCurrentCourse({ year: event.target.value });
  }
  handleWeekChange(event) {
    this.setState({
      week: event.target.value
    })
    this.updateCurrentCourse({ week: event.target.value });
  }

  render() {
    const { classes } = this.props;
    const localizer = momentLocalizer(moment);
    let token = localStorage.getItem('schedule-token');
    if (token) {
      let form = new FormData();
      form.append('token', token);
      if (!localStorage.getItem('schedule-course')) {
        axios.post('https://lug.hit.edu.cn/v1/schedule/course/undergraduate', form).then(
          (response) => {
            localStorage.setItem('schedule-course', JSON.stringify(response.data.retJSON.result))
          }
        )
      }
    }
    return (
      <Grid
        container
        className={classes.gridContainer}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} md={10} xl={9}>
          <Typography variant="h4" className={classes.title}>课程表</Typography>
          <Paper className={classes.paper} elevation={5}>
            <Grid container spacing={2}>
              <Grid item xs={5} sm={3} md={2} xl={1}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="school-year">学年</InputLabel>
                  <Select labelId="school-year" id="school-year" value={this.state.year} onChange={this.handleYearChange}>
                    <MenuItem value="2018-2019">2018-2019</MenuItem>
                    <MenuItem value="2019-2020">2019-2020</MenuItem>
                    <MenuItem value="2020-2021">2020-2021</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} xl={1}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="semester">学期</InputLabel>
                  <Select labelId="semester" id="semester" value={this.state.semester} onChange={this.handleSemesterChange}>
                    <MenuItem value="1">秋</MenuItem>
                    <MenuItem value="2">春</MenuItem>
                    <MenuItem value="3">夏</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} xl={1}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="week">周次</InputLabel>
                  <Select labelId="week" id="week" value={this.state.week} onChange={this.handleWeekChange}>
                    {[...Array(24).keys()].map((number) => <MenuItem value={number + 1} key={number + 1}>{number + 1}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Calendar
              localizer={localizer}
              events={this.state.events}
              startAccessor="start"
              endAccessor="end"
              defaultView="week"
            >

            </Calendar>

            {/* <TableContainer>
              <Table>
                <colgroup>
                  <col style={{ width: "4.8%" }} />
                  <col style={{ width: "13.6%" }} />
                  <col style={{ width: "13.6%" }} />
                  <col style={{ width: "13.6%" }} />
                  <col style={{ width: "13.6%" }} />
                  <col style={{ width: "13.6%" }} />
                  <col style={{ width: "13.6%" }} />
                  <col style={{ width: "13.6%" }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none" align="center">周一</TableCell>
                    <TableCell padding="none" align="center">周二</TableCell>
                    <TableCell padding="none" align="center">周三</TableCell>
                    <TableCell padding="none" align="center">周四</TableCell>
                    <TableCell padding="none" align="center">周五</TableCell>
                    <TableCell padding="none" align="center">周六</TableCell>
                    <TableCell padding="none" align="center">周日</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(11).keys()].map((number) => (
                    <TableRow key={number}>
                      <TableCell className={classes.firstCell}>{number * 2 + 1}-{number * 2 + 2}</TableCell>
                      {[...Array(7).keys()].map((day) => (
                        <ScheduleCell key={day}>{
                          this.state.scheduleCourse[number] ?
                            this.state.scheduleCourse[number][day]
                            : ""}</ScheduleCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles, { withTheme: true })(ScheduleForm);