import { IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EventIcon from '@material-ui/icons/Event';

import React from 'react';
import { AccountCircle } from '@material-ui/icons';

import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default function SideBar(props) {
  const theme = useTheme();
  return (
    <Drawer anchor="left" open={props.open} variant="temporary" onClose={props.handleDrawerClose}>
      <IconButton onClick={props.handleDrawerClose}>
        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
      <div onClick={props.handleDrawerClose}
        onKeyDown={props.handleDrawerClose}>
        <List>
          <ListItemLink to="/" primary="Schedule" icon={<EventIcon />} />
          <ListItemLink to="/login" primary="Account" icon={<AccountCircle />} />
        </List>
      </div>
    </Drawer>
  )
}