import { AppBar, Toolbar, IconButton, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import React from 'react';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  return (
    <AppBar className={classes.root} position="static">
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={props.handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6">
          Schedule
        </Typography>
      </Toolbar>
    </AppBar>
  );

}